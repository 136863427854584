<template>
  <svg
    viewBox="0 0 49 48"
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="48"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.6666 18C26.6666 21.0416 25.3087 23.766 23.166 25.6001C24.4081 26.251 25.5563 27.0907 26.5661 28.1005C29.1916 30.726 30.6666 34.287 30.6666 38C30.6666 39.1046 29.7712 40 28.6666 40C27.5621 40 26.6666 39.1046 26.6666 38C26.6666 35.3478 25.6131 32.8043 23.7377 30.9289C21.8623 29.0536 19.3188 28 16.6666 28C14.0145 28 11.4709 29.0536 9.59556 30.9289C7.72019 32.8043 6.66663 35.3478 6.66663 38C6.66663 39.1046 5.7712 40 4.66663 40C3.56206 40 2.66663 39.1046 2.66663 38C2.66663 34.287 4.14162 30.726 6.76713 28.1005C7.77696 27.0907 8.92518 26.251 10.1672 25.6001C8.02454 23.766 6.66663 21.0416 6.66663 18C6.66663 12.4772 11.1438 8 16.6666 8C22.1895 8 26.6666 12.4772 26.6666 18ZM16.6666 12C13.3529 12 10.6666 14.6863 10.6666 18C10.6666 21.3137 13.3529 24 16.6666 24C19.9803 24 22.6666 21.3137 22.6666 18C22.6666 14.6863 19.9803 12 16.6666 12Z"
    />
    <path
      d="M32.6666 8C31.5621 8 30.6666 8.89543 30.6666 10C30.6666 11.1046 31.5621 12 32.6666 12C34.2579 12 35.784 12.6321 36.9093 13.7574C38.0345 14.8826 38.6666 16.4087 38.6666 18C38.6666 19.5913 38.0345 21.1174 36.9093 22.2426C35.784 23.3679 34.2579 24 32.6666 24C31.5621 24 30.6666 24.8954 30.6666 26C30.6666 27.1046 31.5621 28 32.6666 28C35.3188 28 37.8623 29.0536 39.7377 30.9289C41.6131 32.8043 42.6666 35.3478 42.6666 38C42.6666 39.1046 43.5621 40 44.6666 40C45.7712 40 46.6666 39.1046 46.6666 38C46.6666 34.287 45.1916 30.726 42.5661 28.1005C41.5562 27.0906 40.408 26.251 39.1659 25.6C39.3626 25.4318 39.5534 25.2554 39.7377 25.0711C41.6131 23.1957 42.6666 20.6522 42.6666 18C42.6666 15.3478 41.6131 12.8043 39.7377 10.9289C37.8623 9.05357 35.3188 8 32.6666 8Z"
    />
  </svg>
</template>
